.marketing-form-layout{
    padding: 100px 0;
    .marketing-form-container{
        padding: 50px;
        border-radius: 3px;
        background-color: #f5f5f5;
        .tabular{
            &.menu{
                border-radius: 3px;
                background-color: #5e62670d;
                padding: 5px;
                border: 0;
                font-family: "Be Vietnam", sans-serif !important;
                .item{
                    flex: 1 1 auto;
                    font-weight: 500 !important;
                    padding: 25px 10px 24px;
                    color: #5e6267;
                    border: 0;
                    justify-content: center;
                    &.active{
                        border-radius: 3px !important;
                        color: #3b1c5a;
                        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
        .attached{
            &.segment{
                padding: 40px 0;
                background-color: transparent;
                border: 0;
                p{
                    font-size: 16px;
                    line-height: 2.13;
                    color: #5e6267;
                }
            }
        }
        .form-control{
            border: 0 !important;
            border-radius: 3px !important;
            box-shadow: 0 11px 14px -10px #e5eced !important;
            padding: 34px 20px 20px !important;
            border: 1px solid transparent !important;
            transition: .15s ease-in-out !important;
            &:focus{
                border-color: #374db1 !important;
                box-shadow: unset !important;
            }
        }
        label{
            color: #5e6267;
        }
    }
}

.marketing-banner{
    background: url(/assets/img/marketing/marketing-banner.jpg) no-repeat center !important;
    background-size: cover !important;
    height: 660px;
    position: relative;
    .container{
        height: 100%;
    }
    .row{
        height: 100%;
        align-items: center;
    }
    h2{
        font-size: 30px;
        font-weight: 800;
        line-height: 1.67;
        font-family: "Be Vietnam", sans-serif;
    }
    h5{
        font-size: 16px;
        font-weight: 500;
        line-height: 2.13;
        margin-top: 20px;
        opacity: 0.8;
        font-family: "Be Vietnam", sans-serif;
    }
}


.ui.segment.property-detail-wrapper{
    padding-bottom: 3em;
    box-shadow: none;
    border: 0;
    .ui.five.cards>.card {
        width: calc(20% - 1.5em) !important;
    }
    .ui.card .content .header.unit-card-header {
        margin-bottom: 5px;
        font-family: 'Be Vietnam';
        font-size: 14px !important;
        font-weight: 600;
        color: #171a1d;
        padding-top: 0;
    }
    .property-img{
        max-height: 450px;
        object-fit: cover;
        border-radius: 3px;
    }
}
.semanticModal {
    top: unset !important;
    right: unset !important;
    bottom: unset !important;
    left: unset !important;
    overflow-y: auto;
  }
  .tourRequestModal {
      height:475px;
      .content {
        max-height:531px;
          overflow-y:auto
      }
      select {
        //   background:red !important;
    }
    .form-control {
          height: 50px !important;
        padding:0px;
        line-height: 30px;
        border: 1px solid #e2e2e2; 
        outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
    // min-height: 100px;
	resize: none;
	// line-height: 26px;
      }
      .rld-single-input {
        position: relative;
        input {
            height: 48px;
            line-height: 48px;
            width: 100%;
            padding: 0 20px;
            border: 1px solid #D4D4D4;
            // @include transition(all, 0.4s, ease);
            border-radius: 4px;
            font-size: 15px;
            &:hover, 
            &:active, 
            &:focus {
                border: 1px solid var(--main-color-one);
            } 
        }}
  }

.property-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20%;
}
.tour-form {
    &-container {
      height: 550px;
      width: 500px;
      position: fixed;
      // bottom: 250px;
      background-color: grey;
      right: 50px;
      border-radius: 20px;
      background-color: white;
      z-index: 40;
    }
  }
  .contact-form-wrap {
	background: white;
	border-radius: 10px;
	padding: 30px;
	// box-shadow: $box-shadow;
	.rld-single-input {
		margin-top: 10px;
	}
	button {
		margin-top: 23px;
	    padding: 0 45px;
    	letter-spacing: 0.5px;
	}
	.social-icon {
		margin-top: 20px;
	}
}
.DayPickerInput{
    input {
      background:#f3f3f3!important;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      width: 103%;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
    }
    &-OverlayWrapper {
        padding-top:80px !important
    }
  }

.detail-cards{
    .meta{
        padding-top: 0!important;
    }
}

.button-group-container{
    margin-top: 10% !important;    
    .ui.buttons .button {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.mt-1em {
    margin-top: 1em;
} 

.mb-1em {
    margin-bottom: 1em;
}

.property-details-map {
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0 0px 5px 2px #e5eced;
    background-color: #fff;
    margin-top: 10px;
    .property-location-label{
        font-size: 15px;
        font-weight: bold;
        color: #171a1d;
        margin-bottom: 20px;
    }
    > div {
        // height: 100% !important;
    }
}

.property-detail-layout{
    margin-top: 95px !important;
    .property-information{
        .property-title{
            font-size: 25px;
            font-weight: bold;
            color: #171a1d;
        }
        .property-address{
            color: #5e6267;
            margin-top: 5px;
        }
        .property-info-row{
            display: flex;
            align-items: center;
            margin: 5px -5px 0;
            .property-info-col{
                font-size: 13px;
                font-weight: 500;
                color: #3b1c5a;
                margin: 0 5px 8px;
            }
            .dot-divider{
                width: 4px;
                height: 4px;
                border-radius: 50%;
                margin: 0 5px 5px;
                background-color: #6b66ff;
                opacity: 0.3;
            }
        }
        .property-contact-row{
            display: flex;
            padding: 25px 30px;
            border-radius: 3px;
            background-color: #0000000d;
            margin-top: 25px;
            .property-contact-col{
                width: 50%;
                .contact-label{
                    font-size: 12px;
                    color: #5e6267;
                }
                .contact-info{
                    font-weight: 500;
                    color: #171a1d;
                }
            }
        }
    }
    .property-description{
        font-size: 14px;
        line-height: 2.14;
        color: #171a1d;
        letter-spacing: normal;
        pre {
          max-width:1259px !important;
          overflow-wrap: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    .property-services-row{
        padding: 20px 30px;
        border-radius: 3px;
        box-shadow: 0 0px 5px 2px #e5eced;
        background-color: #fff;
        margin-top: 20px;
        .property-services-title{
            color: #5e6267;
            margin-bottom: 15px;
        }
        .services-list{
            .services-list-item{
                padding: 10px 20px 15px;
                border-radius: 3px;
                border: solid 1px #dedede;
                background-color: #f8f8f8;
                margin-bottom: 10px;
                .services-label{
                    font-size: 13px;
                    font-weight: bold;
                    color: #171a1d;
                    margin-bottom: 2px;
                }
                .services-description{
                    font-size: 12px;
                    line-height: 1.67;
                    color: #5e6267;
                }
            }
        }
    }
    .property-detail-container{
        padding: 23px 16px !important;
        border-radius: 3px !important;
        border: 0 !important;
        box-shadow: 0 0px 5px 2px #e5eced !important;
        background-color: #fff !important;
        margin-top: 20px !important;
        h2{
            font-size: 15px;
            font-weight: bold;
            color: #171a1d;
            margin-bottom: 20px;
        }
        .basic-info-row{
            margin: 0 -8px 20px;
            display: flex;
            .basic-info-col{
                width: 33.33%;
                margin: 0 8px 15px;
                .info-description{
                    color: #171a1d;
                }
            }
        }
        .info-label{
            font-size: 13px;
            color: #5e6267;
            margin-bottom: 5px;
        }
        .amenities-row{
            margin: 0 -5px;
            display: flex;
            flex-wrap: wrap;
            .amenities-col{
                margin: 5px;
                padding: 8px 20px 10px;
                border-radius: 3px;
                border: solid 1px #dedede;
                background-color: #f8f8f8;
            }
        }
    }
}

.property-price-card.ui.card{
    margin-top: -80px !important;
    padding: 25px 25px 20px !important;
    border-radius: 4px !important;
    background-image: linear-gradient(116deg, #3b1c5a, #374db1 161%) !important;
    box-shadow: none !important;
    flex-grow: 0 !important;
    margin-bottom: 30px;
    .price-label{
        font-weight: 500;
        color: #ffffffb3;
    }
    .price-detail{
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        .divider{
            margin: 4px 15px 4px;
            opacity: 0.4;
            color: #fff;
            font-size: 16px;
        }
    }
    .tour-btn{
        button{
            height: 45px;
            font-size: 13px;
            font-weight: 800;
            text-align: center;
            color: #171a1d;
            text-transform: uppercase;
        }
    }
    button{
        border-radius: 3px !important;
    }
}

.property-units-wrapper{
    padding-right: 12px;
    .ui.card{
        .image{
            height: 150px;
            img{
                height: 100% !important;
                object-fit: cover;
            }
        }
        .content{
            padding: 15px !important;
            .prop-unit-info{
                padding-top: 0;
                margin-bottom: 15px;
                display: flex;
                justify-content: space-between;
                // align-items: center;
                strong{
                    font-size: 13px;
                    font-weight: 500;
                    color: #3b1c5a;
                }
                .seperator{
                    min-width: 4px;
                    height: 4px;
                    background-color: #6b66ff4d;
                    border-radius: 50%;
                    margin: 10px;
                }
            }
            .main-button{
                width: 100% !important;
                border-radius: 3px;
                background-color: #3b1c5a1a;
                font-size: 13px;
                font-weight: 800;
                text-align: center;
                color: #3b1c5a !important;
                text-transform: uppercase;
                font-family: 'Be Vietnam';
            }
        }
    }
}


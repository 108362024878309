.top {
    &-service {
        &-background {
            min-height:500px !important;
            background-repeat: no-repeat !important;
            background-size: 100%  !important;
            background-position: center !important;
            background-image: url("/assets/img/Service/Tools.jpg") !important; 
        }
        &-logo {
            padding-left:50px !important;
        }
        &-header {
            color:white !important; 
        }
        &-info {
            color: white !important;
            max-width: 50% !important;
            margin-left: 25% !important;
        }
    }
}
.middle {
    &-service {
        &-empty {
            min-height:650px !important;
        }
        
        &-background {
            min-height:650px !important;
            background-image: url("/assets/img/Service/Painting.jpg")  !important; 
            background-repeat: no-repeat !important;
            background-size: 100%  !important;
            background-position: center !important;
            &-two {
                min-height:650px !important;
            background-image: url("/assets/img/Service/Pipes.jpg")  !important; 
            background-repeat: no-repeat !important;
            background-size: 100%  !important;
            background-position: center !important;
            }
        }
    }
}
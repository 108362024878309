.top-privacy-background {
    height: 500px;
    background-image: url(/assets/img/Contact/contact-page-hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.top-privacy-header {
    color: #fff;
    font-size: 52px;
    font-weight: 900;
    margin-bottom: 0.5em;
}

.privacy-data-container {
    margin-top: 30px;
    padding: 40px;
    p {
        &:last-child {
            margin-bottom: 1em;
        }
    }
    h4 {
        margin-bottom: 0.5em;
        font-weight: 500;
        font-size: 14px;
    }
    h3 {
        font-size: 1.17em;
    }
}
.property-manager-wrapper {
      .fixed-top-image {
    height:60.31px !important;
    margin-bottom:10px;
  }
    .home-banner {
        background-image: url(/assets/img/marketing/property-manager-banner.jpg);
    }
    .home-widgets {
        background-color: $white;
        &.manager-widgets {
            .widget-col {
                @media (max-width: 768px) {
                    margin: 20px 0 0;
                }
            }
        }
    }
    .widget-rw {
        border-radius: 3px;
        background-color: #f5f5f5;
        height: 256px;
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        @media (max-width: 768px) {
            height: 180px;
        }
        &:hover {
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
            background-color: $white;
        }
        .widget-image {
            background-color: transparent;
            box-shadow: unset;
            padding: 0px;
            width: auto;
            height: auto;
            &.cost-saving {
                img {
                    width: 35px;
                }
            }
        }
        .widget-content {
            h4 {
                font-size: 16px;
                font-weight: 800;
                margin-top: 25px;
                margin-bottom: 5px;
                line-height: 24px;
            }
            p {
                line-height: 24px;
                font-weight: normal;
            }
        }
    }
    .home-image-section {
        background-color: #f8f8f8;
        padding: 120px 0 130px;
        &.manager-time-wrap {
            .win-rw {
                .fixed-square-image {
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
            .right-wrap {
                @media (max-width: 768px) {
                    margin-top: 100px;
                }
            }
        }
        @media (max-width: 768px) {
            padding: 40px 0px;
        }
        .row {
            @media (max-width: 768px) {
                flex-direction: unset;
                h3 {
                    margin-top: 0px;
                }
            }
        }
        .image-container {
            height: 390px;
            @media (max-width: 768px) {
                height: auto;
            }
            .fixed-left-image {
                right: -50px;
                left: unset;
                bottom: 40px;
                z-index: 999;
                
                width: auto;
                @media (max-width: 992px) {
                    right: -20px;
                }
                @media (max-width: 768px) {
                    width: 150px;
                    height: 150px;
                    object-fit: contain;
                    right: -27px;
                    bottom: 175px;
                }
            }
            .fixed-square-image {
                bottom: 0px;
                top: -30px;
                z-index: 9;
                right: -30px;
                left: auto;
            }
            .main-image {
                position: relative;
                right: 0px;
                width: 98%;
                height: 100%;
                z-index: 99;
                @media (max-width: 992px) {
                    right: 13px;
                    width: 100%;
                }
                @media (max-width: 768px) {
                    right: 0;
                    padding-right: 12px;
                    height: 390px;
                    object-fit: cover;
                }
            }
        }
    }
}
.win-rw {
    position: relative;
    background-color: #f5f5f5;
    right: 0px;
    z-index: 99;
    @media (max-width: 992px) {
        margin-left: 20px;
    }
    .fixed-left-image {
        width: 305px;
        position: absolute;
        top: 190px;
        left: -70px;
        
        z-index: 9;
        @media (max-width: 992px) {
            left: -60px;
            top: 160px;
        }
    }
    .main-image {
        position: relative;
        top: 0px;
        right: -25px;
        width: 100%;
        height: 100%;
        @media (max-width: 992px) {
            width: 100%;
        }
    }
    .fixed-square-image {
        position: absolute;
        bottom: -28px;
        left: -28px;
    }
}

.content-rw {
    text-align: center;
    padding: 55px 65px 60px;
    background-color: #f5f5f5;
    @media (max-width: 768px) {
        padding: 80px 20px 30px;
    }
    h4 {
        font-size: 20px;
        font-weight: bold;
        color: #171a1d;
    }
    p {
        font-size: 15px;
        font-weight: normal;
        line-height: 28px;
        color: #5e6267;
    }
}

.management-wrapper {
    border-radius: 3px;
    background-color: #f5f5f5;
    width: 98%;
    float: right;
    margin-top: 50px;
    position: relative;
    padding-top: 10px;
    @media (max-width: 768px){
        width: 100%;
    }
    img {
        width: 230px;
        margin: 0px auto;
        text-align: center;
        display: block;
        &.fixed-middle-image {
            width: 200px;
            margin-left: 190px;
            margin-top: -10px;
            @media (max-width: 768px){
                margin: 0px auto;
            }
        }
    }
    .content-rw {
        text-align: center;
        padding-bottom: 61px;
        padding-top: 5px;
        h4 {
            font-size: 20px;
            font-weight: bold;
            color: #171a1d;
        }
        p {
            font-size: 15px;
            font-weight: normal;
            line-height: 28px;
            color: #5e6267;
            max-width: 360px;
            margin: 0px auto;
        }
    }
}
.property-section {
    h3 {
        max-width: 330px;
        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
    .image-container {
        height: 100%;
        text-align: left;
        @media (max-width: 768px) {
            margin-top: 50px;
        }
        .renter-image {
            position: relative;
            left: 0px;
            @media (max-width: 768px) {
                width: 100%;
                padding-left: 15px;
            }
        }
        .fixed-top-image {
            position: absolute;
            right: 95px;
            top: 38px;
           
            @media (max-width: 768px) {
                right: -27px;
    width: 150px;
    height: 150px;
    object-fit: contain;
            }
        }
        .fixed-square-image {
            right: auto;
            left: -29px;
            top: -29px;
            bottom: auto;
        }
        .fixed-left-image {
            left: -75px;
            bottom: 0px;
            
            @media (max-width: 1200px) {
                bottom: 60px;
            }
            @media (max-width: 992px) {
                left: -45px;
            }
            @media (max-width: 768px) {
                bottom: -10px;
            }
        }
    }
}
.lease-management-section {
    background-color: $white !important;
    p {
        max-width: 650px;
        margin: 0px auto 85px !important;
        @media (max-width: 768px) {
            margin: 0px auto 10px !important;
        }
    }
    .win-container {
        @media (max-width: 768px) {
            margin-top: 145px;
        }
        .main-image {
            top: 20px;
            right: -29px;
            width: 100%;
            position: absolute;
        }
        .content-rw {
            padding: 485px 65px 60px;
            @media (max-width: 768px) {
                padding: 290px 20px 30px;
            }
            h4 {
                margin-top: 0px;
                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }
            p {
                margin-bottom: 0px !important;
            }
        }
        .fixed-left-image {
            top: 100px;
            left: -40px;
            width: auto;
            
            @media (max-width: 768px){
                width: 150px;
    height: 150px;
    object-fit: contain;
    left: -47px;
            }
        }
        .fixed-square-image {
            z-index: -1;
            bottom: auto;
            top: -28px;
        }
    }
    .image-container {
        height: 643px;
        @media (max-width: 768px) {
            height: auto;
        }
        .fixed-top-image {
            width: 305px;
            z-index: 9;
            position: absolute;
            right: -32px;
            top: 75px;
            z-index: 99;
           
        }
        .fixed-square-image {
            bottom: -78px;
            top: auto;
            z-index: 9;
            right: 8px;
            @media (max-width: 768px) {
                right: -29px;
                bottom: -80px;
            }
        }
        .renter-image {
            position: relative;
            right: 0px;
            top: 50px;
            z-index: 9;
            width: 100%;
            height: 100%;
            object-fit: contain;
            @media (max-width: 768px) {
                padding-right: 15px;
            }
        }
    }
}
.manage-properties-section {
    background-image: linear-gradient(94deg, #3b1c5a, #374db1 163%);
    margin-top: 50px;
    position: relative;
    margin-bottom: 90px;
    .badge-btn {
        width: 200px;
        height: 30px;
        background-color: #11ae7d;
        padding: 0 25px;
        border-radius: 15px;
        font-size: 12px;
        font-weight: bold;
        line-height: 30px;
        color: $white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    h4 {
        font-size: 40px;
        font-weight: 800;
        color: $white;
        max-width: 441px;
        line-height: 60px;
        margin-bottom: 26px;
        @media (max-width: 768px) {
            font-size: 25px;
            line-height: 1.8;
        }
    }
    p {
        font-size: 16px;
        font-weight: normal;
        line-height: 36px;
        color: $white;
        max-width: 470px;
        opacity: 0.7;
        @media (max-width: 768px) {
            font-size: 15px;
            line-height: 30px;
        }
    }
    .image-container {
        position: absolute;
        right: 0px;
        @media (max-width: 1200px) {
            bottom: -55px;
            img {
                max-width: 80%;
            }
        }
        @media (max-width: 992px) {
            bottom: 0;
            img {
                max-width: 65%;
            }
        }
        @media (max-width: 768px) {
            position: unset;
            margin-bottom: 24px;
        }
    }
}

// Explore services section
.explore-services-section{
    .row{
        max-width: 1200px;
        margin: 50px auto 0;
    }
    .col-md-3{
        margin-bottom: 35px;
    }
    .services-card{
        padding: 30px 20px;
        border-radius: 3px;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        text-align: center;
        height: 100%;
        .services-icon{
            margin-bottom: 20px;
        }
        .services-card-title{
            font-size: 14px;
            font-weight: bold;
            line-height: 1.86;
            color: #171a1d;
            font-family: "Be Vietnam", sans-serif;
        }
        .services-card-description{
            font-size: 13px;
            line-height: 2;
            color: #5e6267;
            font-family: "Be Vietnam", sans-serif;
            margin-top: 10px;
        }
    }
}

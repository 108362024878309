@import "../../theme/variables.scss";

.blog-containter{
padding-top: 3rem;
width: 92% !important;
h1{
font-weight: 500 !important;
padding-bottom: 2rem !important;
}
.ui {
&.card {
box-shadow: none !important;
> .extra {
border-top: 1px solid transparent !important;
margin-top: 8px;
margin-bottom: 15px;
}
}
}
}

.clap-icon {
display: inline-block;
width: 14px;
height: 15px;
background: url('/assets/img/clap.svg');
background-repeat: no-repeat;
background-position: center;
opacity: 0.3;
margin: 0 5px;
vertical-align: -1px;
}

.slick-slider {
    .slick-prev:before {
        color:#3b1d5b !important;
        height:100% !important;
    }
    .slick-next:before {
        color:#3b1d5b !important;
        height:100% !important;
    }
}

.blog-card {
    padding:10px !important;
    .image {
        width: 500px;
    height: 250px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.description-blog {
    max-height:102px !important;
    height:1000px !important;
    p{
        text-overflow: ellipsis !important;

    }
    overflow: hidden; 
    img {
        display:none !important;
    }
    figure {
        display:none !important;
    }
    h3 {
        display:none !important;
    }
    strong {
        display:none !important;
    }
}

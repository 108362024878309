.banner-local-sites {
    background: url(/assets/img/marketing/local-site-banner.jpg) no-repeat center;
    background-size: cover;
    color: $white;
    padding-top: 95px;
    h1 {
        font-size: 30px;
        font-weight: 800;
        margin: 85px 0 7px;
    }
    h4 {
        opacity: 0.7;
        font-size: 16px;
        max-width: 600px;
        margin: 0px auto 146px;
        line-height: 26px;
        font-weight: normal;
    }
}
.local-sites-wrapper {
    .half-image-section {
        .badge {
            width: 240px;
        }
    }
    .full-back-image-section {
        padding: 60px 0 50px;
        .image-container {
            background: url(/assets/img/marketing/ls_image_1.jpg) no-repeat center;
            background-size: cover;
            h3 {
                max-width: 499px;
            }
        }
    }
    .benifit-section {
        padding: 100px 0;
        h3 {
            margin-bottom: 15px;
            color: #4c158a;
            max-width: 355px;
        }
        .image-container {
            height: 100%;
            .fixed-left-image {
                left: -18px;
                bottom: 40px;
                z-index: 99;
            }
            .main-image {
                position: relative;
                right: 0px;
                z-index: 9;
            }
            .fixed-square-image {
                right: -30px;
                bottom: -15px;
            }
        }
    }
}
.services-platform {
    margin-bottom: 100px;
    .service-platform-wrap {
        background-image: linear-gradient(105deg, #3b1c5a, #374db1 162%);
        margin: 0px;
        padding: 50px;
        .services-platform-col {
            text-align: center;
            .platform-image {
                background-color: rgba(255, 255, 255, 0.2);
                width: 100px;
                height: 100px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px auto 20px;
            }
            .platform-content {
                color: $white;
                h4 {
                    font-size: 18px;
                    font-weight: 800;
                }
                p {
                    font-size: 13px;
                    line-height: 28px;
                    font-weight: normal;
                    opacity: 0.7;
                }
            }
        }
    }
}

.service-guide {
    padding-bottom: 166px;
    padding-top: 100px;
    .top-header {
        h5 {
            font-family: "jenna_sueregular";
            text-align: center;
            font-size: 40px;
            font-weight: normal;
            color: #171a1d;
        }
        h3 {
            font-size: 30px;
            font-weight: 800;
            text-align: center;
        }
        p {
            font-size: 16px;
            font-weight: normal;
            max-width: 700px;
            line-height: 32px;
            color: #5e6267;
            margin: 0px auto 30px;
            text-align: center;
        }
    }

    .card-carousel {
        display: flex;
        .content-rw {
            padding: 30px;
            border-radius: 3px;
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
            background-color: $white;
            margin: 20px;
            .header-wrap {
                img {
                    width: 100px;
                    height: 100px;
                    margin-right: 20px;
                }
                .reviewr-detail {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h5 {
                        font-size: 20px;
                        font-weight: bold;
                        color: #171a1d;
                        margin-bottom: 5px;
                    }
                    h6 {
                        font-size: 13px;
                        font-weight: normal;
                        color: #5e6267;
                    }
                }
            }
            .strip-rw {
                border-radius: 3px;
                background-color: rgba(59, 28, 90, 0.1);
                margin-top: 25px;
                margin-bottom: 25px;
                display: flex;
                padding: 7px 15px;
                justify-content: space-between;
                .left-side {
                    span {
                        font-size: 12px;
                        color: #3b1c5a;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }

                .fa-star {
                    font-size: 12px;
                    color: #e9a528;
                    margin-left: 4px;
                }
            }
            p {
                font-size: 13px;
                font-weight: normal;
                line-height: 26px;
                color: #5e6267;
            }
        }
    }
    .carousel-indicators {
        transform: translate(-50%, 0px);
        background-color: rgba(23, 26, 29, 0.6);
        bottom: -50px;
    }
}
.book-now {
    width: 300px;
    height: 45px;
    font-size: 13px;
    font-weight: 800;
    border-radius: 3px;
    background-color: $white;
    color: #171a1d;
    margin-top: 40px;
    text-transform: uppercase;
    border: 0px;
}
// maintenance professional scss
.maintenance-wrapper {
    .banner-local-sites {
        background: url(/assets/img/marketing/mp-banner.jpg) no-repeat center;
        background-size: cover;
    }
    .half-image-section {
        .badge {
            width: 200px;
        }
    }
    .full-back-image-section {
        padding: 120px 0 50px;
        .image-container {
            background: url(/assets/img/marketing/mp_image_3.jpg) no-repeat center;
            background-size: cover;
        }
        h3{
            max-width: 506px;
        }
    }
    .book-now {
        width: 330px;
    }
    .benifit-section {
        padding: 100px 0;
        h3 {
            max-width: 372px;
            color: #4c158a;
        }
        .fixed-left-image {
            left: -25px;
        }
    }
}

// Grocery Shopping scss
.grocery-shopping-wrapper {
    .banner-local-sites {
        background: url(/assets/img/marketing/grocery-banner.jpg) no-repeat center;
        background-size: cover;
    }
    .half-image-section {
        .badge {
            width: 220px;
        }
    }
    .full-back-image-section {
        padding: 120px 0 50px;
        .image-container {
            background: url(/assets/img/marketing/gs_image_2.jpg) no-repeat center;
            background-size: cover;
        }
    }
    .benifit-section {
        padding: 100px 0;
        h3 {
            max-width: 384px;
            color: #4c158a;
        }
        .fixed-left-image {
            left: -25px;
        }
    }
}

// pet services scss
.pet-services-wrapper {
    .banner-local-sites {
        background: url(/assets/img/marketing/ps-banner.jpg) no-repeat center;
        background-size: cover;
        h4 {
            max-width: 650px;
        }
    }
    .half-image-section {
        .badge {
            width: 150px;
        }
    }
    .full-back-image-section {
        padding: 120px 0 50px;
        .image-container {
            background: url(/assets/img/marketing/ps_image_4.jpg) no-repeat center;
            background-size: cover;
            h3 {
                max-width: 386px;
            }
        }
    }
    .benifit-section {
        padding: 100px 0;
        h3 {
            max-width: 384px;
            color: #4c158a;
        }
        .fixed-left-image {
            left: -25px;
        }
        .last-image-row {
            margin-top: 57px;
            position: relative;
            .image-container {
                text-align: left;
                .last-image-2 {
                    position: absolute;
                    right: 0px;
                    z-index: 9;
                }
            }
        }
    }
}
.maintenance-wrapper, 
.grocery-shopping-wrapper, 
.pet-services-wrapper {
    .half-image-section {
        &.expert-section {
            .image-container {
                height: 100%;
                .fixed-left-image {
                    bottom: -30px;
                    z-index: 99;
                }
                .main-image {
                    position: relative;
                    right: 0px;
                    z-index: 9;
                }
                .fixed-square-image {
                    bottom: -90px;
                }
            }
            h3{
                margin-top: 0px;
            }
        }
    }
}
// Platform Products scss
.platform-products-wrapper {
    .banner-service-provider {
        background: url(/assets/img/marketing/platform-banner.jpg) no-repeat center;
        background-size: cover;
    }
    .half-image-section {
        .badge {
            width: 160px;
        }
        &.expert-section {
            margin-bottom: 90px;
            padding-bottom: 0px;
            @media (max-width: 768px){
                margin-bottom: 50px;
            }
            .image-container {
                .fixed-left-image {
                    bottom: 40px;
                    z-index: 99;
                }
                .main-image {
                    position: relative;
                    right: 0px;
                    z-index: 9;
                }
                .fixed-square-image {
                    bottom: -30px;
                }
            }
        }
    }
    .full-back-image-section {
        padding: 120px 0 50px;
        .image-container {
            background: url(/assets/img/marketing/ps_image_4.jpg) no-repeat center;
            background-size: cover;
            h3 {
                max-width: 386px;
            }
        }
    }
    .benifit-section {
        padding: 100px 0 90px;
        @media (max-width: 768px){
            padding: 50px 0;
        }
        .image-container {
            // height: 100%;
            text-align: left;
            .fixed-left-image {
                left: unset;
                right: -28px;
                z-index: 999;
                bottom: 20px;
            }
            .main-image {
                position: relative;
                left: 0px;
                z-index: 99;
                padding-right: 0px;
                padding-left: 20px;
            }
            .fixed-square-image {
                right: 0px;
                bottom: -30px;
                left: -10px;
                z-index: 9;
            }
        }
        .fixed-left-image {
            left: -25px;
        }
    }
}

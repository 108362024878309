.privacy-wrapper {
    h1 {
        margin-bottom: 105px;
        font-size: 40px;
    }
    .privacy-wrapper {
        padding: 50px 0px;
        .content-data {
            margin-bottom: 30px;
            span {
                font-weight: bold;
                margin-bottom: 30px;
                display: block;
            }
        }
        ul {
            list-style: none;
            padding: 0px;
            li {
                margin-bottom: 50px;
            }
            &.child-element {
                li {
                    p {
                        display: inline;
                    }
                }
            }
            &.subchild-element {
                list-style: disc;
                padding-left: 40px;
                li {
                    margin-bottom: 20px;
                }
            }
        }
        h4 {
            font-size: 20px;
            line-height: 50px;
            font-weight: bold;
            color: #3b1c5a;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        p {
            font-size: 17px;
            line-height: 36px;
            font-weight: normal;
            color: #5e6267;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
        &.terms-wrapper{
            ul {
                &.subchild-element {
                    li {
                        p {
                            display: inline;
                        }
                        span{
                            margin-right: 15px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
   
}

.btn {
    padding: 0 10px;
    border-radius: 3px;
    border: 0;
    font-size: 13px;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    background: none;
    font-family: "Be Vietnam", sans-serif;
    &+.btn {
        margin-left: 10px;
    }

    &.btn-primary {
        // background: $primary-color;
        color: white;
        border: 0;
    }

    &:focus,
    &:active {
        box-shadow: none !important;
        outline:0;
    }

    &:active,
    &:hover {
        // background: $primary-site-color;
        color: white;
    }

    &:active,
    &.modal-open,
    &:focus:active,
    &:active:focus {
        box-shadow: none;
        // background: $primary-site-color;
        color: white;
        border-color: #393e41;
    }
}

.btn-transparent {
    width: 100px;
    height: 35px;
    padding: 5px 0 7px;
    border-radius: 3px;
    background-color: rgba($white, 0.15);
    font-family: 'Be Vietnam', sans-serif;
    &:hover {
        // background-image: linear-gradient(94deg, #3b1c5a, #374db1 163%);
        color: #fff;
    }
    &:active{
        // background: $trasparent-btn-color ;
        color: #fff;
        &:focus{
            // background-image: linear-gradient(94deg, #3b1c5a, #374db1 163%);
            color: #fff;
        }
    }
}
.btn-blue{
    border-radius: 5px;
    background: #0678b0;
    color: #fff;
    border: 0;
    height: 60px;
    &:hover {
        background: #0678b0;
        color: #fff;
        border: 0;
    }
    &:active{
        background: #0678b0;
        color: #fff;
        border: 0;
    }
}
.btn-color{
    height: 45px;
    padding: 11px 0 13px;
    border-radius: 3px;
    background-image: linear-gradient(96deg, #3b1c5a, #374db1 163%) !important;
    color: #fff !important;
    font-family: "Be Vietnam", sans-serif !important;
}
.btn-sm{
    height: 38px;
    padding-top: 8px !important;
    min-width: 110px;
}

.btn-white{
    background-color: #fff;
    color: #171a1d;
    min-width: 140px;
    font-size: 13px;
    font-weight: 800;
    border-radius: 3px;
    padding: 7px 20px 9px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.btn-gradient{
    background-image: linear-gradient(102deg, #3b1c5a, #374db1 162%) !important;
    color: #fff !important;
    text-transform: uppercase !important;
    display: flex !important;
    align-items: center;
    font-size: 13px !important;
    font-family: 'Be Vietnam', sans-serif !important;
    text-align: center;
    justify-content: center;
  }
.btn-gray{
    background-color: #e1e1e1 !important;
    font-size: 12px;
    font-weight: normal !important;
    color: #171a1d !important;
}

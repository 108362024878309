// Breakpoints each one less then antd one
$mobile: 480px;
$tablet: 768px;
$medium-desktop: 992px;

// Colors
$white: #ffffff;
$black: #000000;
$blue-light: #f2fcfb;
$gold: #ffef43;
$gold-dark: #f3e018;
$grey-main: #021e40;
$grey-middle: #7f7f7f;
$grey-light: #f9f9fa;
$grey-light-text: #96a0ad;
$grey-dark: #292c39;
$grey-link: #091e3e;
$grey-placeholder: #bfbfbf;
$grey-background: #f2f3f5;
$green-splash: #01c7aa;
$green-primary: #01c7aa;
$purple-primary:#4c158a;
$green-light: #8addd1;
$purple-light: #b7a1d0;
$green-white: #f2fcfb;
$purple-white:#ede8f3;
$green-dark: #029d87;
$purple-dark:#44137c;
$green-splash-light: #01dba8;
$purple-splash-light: #9473b9;
$purple-splash-dark: #44137c;
$green-splash-dark: #007766;
$grey:#c7c5c5;
$light-blue: #E6F7FF;
// #EFF5FE

$primary-color-hover: lighten($purple-primary, 10);

$simple-color: $purple-primary;
$simple-bgcolor: transparent;
$simple-color-hover: $green-light;
$simple-bgcolor-hover: transparent;

// Layout
$footerHeight: 168px;
$headerHeight: 93px;

$input-background:                              #FFFFFF;
$input-border-color:                            transparent;
$input-placeholder:                             #f3f3f6;
$input-text:                                    #171a1d;
$input-label-color:                             #898da0;
$error-color:                                   #ff0000;
$input-focus-border-color:                      #374db1;

// custom dropdown
$dropdown-background:                           #f3f3f6;




.contact-banner {
    background: url(/assets/img/marketing/faq-banner.jpg) no-repeat center;
    background-size: cover;
  }
  
  .contact-section {
    background-color: #fafafa;
    &.demo-request{
      padding-top: 100px;
    }
    .contact-map {
      padding: 100px 0 50px;
      .container {
        position: relative;
        padding: 0;
        .fixed-square-image {
          position: absolute;
          z-index: 10;
          left: -25px;
          top: -25px;
        }
      }
      .contact-inner {
        border-radius: 3px;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 50px;
        position: relative;
        z-index: 20;
      }
      .contact-mapContent {
        margin: 0 0 50px;
        &:last-child {
          margin: 0;
        }
        h5 {
          font-size: 16px;
          font-weight: 800;
          color: #3b1c5a;
          margin: 0 0 15px;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          margin: 10px 0 0;
          color: #5e6267;
        }
      }
    }
    .contact-form {
      padding-bottom: 100px;
      .container {
        padding: 50px;
        border-radius: 3px;
        background-color: #f5f5f5;
      }
      .faqs-section {
        margin: 0;
      }
      .nav-tabs {
        background: #ededed;
      }
      .contact-formField {
        margin: 40px 0 0;
      }
      .contact-text {
        font-size: 16px;
        line-height: 2.13;
        color: #5e6267;
      }
      .checkbox-group {
        border-radius: 3px;
        padding: 15px 20px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #fff;
        .checkbox-label {
          position: static;
        }
        p {
          font-size: 12px;
          font-weight: 500;
          color: #5e6267;
        }
      }
      .form-button {
        margin: 30px 0 0;
      }
    }
  }
  
  
  .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    margin: 0 0 10px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.92;
    left: auto;
    top: auto;
    color: #5e6267;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark {
        background-image: linear-gradient(133deg, #3b1c5a, #374db1 158%);
        &:after {
          display: block;
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 5px;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #eee;
      &:after {
        left: 5px;
        top: 1px;
        content: "";
        position: absolute;
        display: none;
        width: 6px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
  
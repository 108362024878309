.renter-banner {
  .carousel-caption {
    transform: translate(0px, -50%) !important;
    padding-top: 0px;

    p {
      margin: 20px 0 30px;
    }
  }
  .renter-form {
    .fields {
      margin: 0px;
    }
  }

  .container {
    position: relative;
  }
  .renter-search {
    position: absolute !important;
    top: 80%!important;
    z-index: 4;
    left: 0;
    margin: 0px 10px;
    .renter-form {
      display: flex;
    }
    .form-control {
      width: 400px !important;
      height: 70px;
      padding: 0px;
      border: 0;
      box-shadow: none;
      border-radius: 3px 0 0 3px;
      @media (max-width: 520px) {
        width: 210px !important;
      }
      input {
        padding: 0px 30px;
        color: #171a1d;
        font-size: 14px;
        font-weight: 500;
        margin: 0px;
        border: 0px;
        &::placeholder {
          color: #5e6267;
        }
      }
    }
    .renter-btn {
      border-radius: 0 3px 3px 0;
      width: 80px;
      height: 70px;
      border: 0;
      background-image: linear-gradient(129deg, #3b1c5a, #374db1 158%);
      margin-left: -7px;
      padding: 0px;
    }
  }
}
.renter-image-section {
  .row {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .fixed-square-image {
    left: -10px;
    right: unset;
    bottom: unset;
    top: -30px;
  }
  .renter-image {
    left: -35px;
    position: relative;
  }
  .main-image {
    padding-right: 100px;
    padding-left: 20px;
    @media (max-width: 768px) {
      padding-right: 0px !important;
    }
  }
  .fixed-top-image {
    width: 150px;
    height: 150px;
    object-fit: contain;
    position: absolute;
    right: 55px;
    top: 40px;
    @media (max-width: 768px) {
      width: 120px;
      height: 120px;
      right: -6px;
    }
  }
  .fixed-left-image {
    left: -50px !important;
    width: 300px;
  }
}
.renter-point-section {
  h3 {
    @media (max-width: 768px) {
      margin-top: 50px;
    }
  }
  .main-image {
    padding-left: 100px;
    height: 450px;
    @media (max-width: 768px) {
      height: auto;
      padding-left: 0px;
    }
  }
  .fixed-top-image {
    width: 150px;
    height: 150px;
    object-fit: contain;
    position: absolute;
    bottom: 30px;
    left: 135px;
    @media (max-width: 768px) {
      width: 120px;
      height: 120px;
      left: -6px;
    }
  }
  .fixed-left-image {
    left: unset !important;
    bottom: unset;
    top: 25px;
    right: -55px;
    width: 300px;
    @media (max-width: 768px) {
      right: -40px;
    }
  }
}
.renterTab-section {
  margin: 65px 0 0;
  .nav-tabs {
    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      @media (max-width: 768px) {
        justify-content: flex-start;
        background-image: url("/assets/img/marketing/icons-plus.svg");
        background-repeat: no-repeat;
        background-position: center right 0px;
      }
      &.active {
        img {
          filter: grayscale(0%);
        }
      }
      img {
        margin: 0 5px 0 0;
        width: 25px;
        filter: grayscale(100%);
      }
    }
  }
  .tab-content {
    @media (max-width: 768px) {
      .main-image,
      .fixed-square-topLeft {
        display: none;
      }
      .renter-tabContent {
        min-height: 320px;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 9;
        margin-bottom: 40px;
      }

      #collapseOne {
        .renter-tabContent {
          background-image: url("/assets/img/marketing/pet-image.jpg");
        }
      }
      #collapsetwo {
        .renter-tabContent {
          background-image: url("/assets/img/marketing/grocery-shopImage.jpg");
        }
      }
      #collapsethree {
        .renter-tabContent {
          background-image: url("/assets/img/marketing/maintenance-image.jpg");
        }
      }
      #collapsefour {
        .renter-tabContent {
          background-image: url("/assets/img/marketing/localSite-image.jpg");
        }
      }
    }
  }
  .renter-tabContent {
    position: relative;
    .fixed-square-topLeft {
      position: absolute;
      left: -15px;
      top: -15px;
      z-index: -1;
    }
    .main-image {
      width: 100%;
      position: relative;
      z-index: 50;
    }
    .fixed-square-bottomRight {
      position: absolute;
      right: -25px;
      bottom: -25px;
      z-index: 10;
    }
    .renter-card {
      position: absolute;
      width: 305px;
      padding: 30px;
      border-radius: 3px;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      right: 0;
      top: 0;
      margin: 20px;
      bottom: 0;
      z-index: 100;
      text-align: left;
      .renter-cardIcon {
        background: #f5f3f7;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
      }
      h5 {
        font-size: 15px;
        font-weight: bold;
        line-height: 1.6;
        color: #3b1c5a;
        margin: 25px 0 10px;
      }
      p {
        font-size: 13px !important;
      }
      a {
        font-size: 13px;
        font-weight: 600;
        color: #3b1c5a;
        text-decoration: underline;
        margin: 10px 0 0;
        display: inline-block;
      }
    }
  }
}

// renter search css
.renter-listing-header {
  .transparent-header {
    background-image: linear-gradient(94deg, #3b1c5a, #374db1 163%);
  }
}
.renter-header-search {
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #fff;
  min-height: 70px;
  // margin-top: 95px;

  position: fixed;
  top: 95px;
  z-index: 9;
  width: 100%;
  .renter-form {
    padding-top: 16px;
    .inner-dropdown{
      .dropdown-option{
        padding: 6px 10px;
        cursor: pointer;
        font-size: 13px;
        color: #5e6267;
      }
    }
    .renter-search-listing-types{
      display: flex;
      align-items: center;
      padding: 0 20px 2px;
      border-radius: 3px;
      border: solid 1px #e1e1e1;
      height: 38px;
      margin-left: 10px;
      .field{
        margin-bottom: 0 !important;
        color: #5e6267;
      }
      input.hidden{
        height: 0 !important;
      }
      label{
        top: 0;
        left: 10px;
        padding-right: 6px;
        color: #5e6267;
        &::before, &:after{
          top: 5px;
        }
      }
    }
    .fields {
      flex-wrap: wrap;
    }
    .field {
      padding: 0px 5px !important;
      margin-bottom: 10px!important;     
      .form-control {
        padding: 0px;
        box-shadow: unset;
        height: 38px;
      }
      input,
      .ui.selection {
        height: 38px;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        font-size: 12px;
        font-weight: normal;
        color: #5e6267;
      }
      .ui.selection {
        width: 80px;
      }
      &.listing-type{
        .selection{
          width: 120px !important;
          .inner-dropdown{
            width: 200px;
          }
        }
      }
      &.services-type{
        .selection{
          width: 190px !important;
        }
      }
      .date-container {
        .react-datepicker-wrapper {
          width: 120px;
          &:nth-child(1) {
            padding-right: 10px;
          }
        }
      }
      .min-max-input {
        display: flex;
        flex-direction: column;
        .ui.selection {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    .search-btn {
      border-radius: 3px;
      background-image: linear-gradient(108deg, #3b1c5a, #374db1 162%) !important;
      width: 111px;
      height: 38px;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
      margin-left: 5px;
    }
  }
}
.renter-sub-header {
  background-color: #f8f8f8;
  margin-top: 172px;
  h5 {
    padding: 30px 0px;
    font-size: 15px;
    font-weight: normal;
    color: #171a1d;
  }
}
.ui.center.segment {
  box-shadow: unset;
  border: 0px;
  padding: 0px;
}
.renter-search-listing {
  border: 0px;
  box-shadow: unset;
  background-color: #f8f8f8;
  padding-bottom: 100px !important;
  padding-top: 15px !important;
  .search-listing-wrapper {
    display: flex;
    @media (max-width: 992px){
      flex-direction: column-reverse;
    }
    .card-listing {
      width: 65%;
      padding-right: 20px;
      @media (max-width: 992px){
        padding-right: 0px;
        width: 100%;
      }
      .property-search-card-scroll{
        margin: 0px;
      }
    }
    .show-map {
      width: 35%;
      @media (max-width: 992px){
        padding: 0px 15px;
        margin-bottom: 30px;
        width: 100%;
      }
    }
    .property-card-container {
      box-shadow: 0 11px 14px -10px #e5eced !important;
      background-color: #fff;
      border-radius: 3px !important;
      padding: 20px;
      margin: 0px 15px 15px 0px;
      .content {
        position: relative;
        display: flex;
        align-items: center;
        @media (max-width: 540px){
          flex-wrap: wrap;
        }
      }
      .image-wrapper{
        width: 250px !important;
        height: 130px !important;
        margin-right: 15px;
        @media (max-width: 540px){
          width: 100%!important;
          margin-right: 0px;
        }
      }
      img {
        border-radius: 3px;
        width: 100% !important;
        height: 100% !important;
        opacity: 0.8;
      }
      .content-wrapper{
        flex-grow: 1;
        @media (max-width: 540px){
          padding: 10px;
          width: 100%;
        }
      }
      .header {
        font-size: 17px !important;
        font-weight: 600;
        color: #171a1d;
        padding-top: 0px;
      }
      .meta-address {
        color: #5e6267;
        font-size: 14px;
        font-weight: normal;
      }
      .meta-details {
        strong {
          font-size: 13px;
          font-weight: 500;
          color: #3b1c5a;
        }
      }
      .meta-details {
        display: flex;
        align-items: center;
        strong {
          font-size: 13px;
          font-weight: 500;
          color: #3b1c5a;
          margin-right: 0px;
        }
        .seperator {
          width: 4px;
          height: 4px;
          display: inline-block;
          background-color: rgba(107, 102, 255, 0.3);
          border-radius: 50%;
          margin: 0px 10px;
        }
      }
      .rental-service-col{
        display: flex;
        justify-content: flex-end;
        .service-label{
          font-size: 12px;
          font-weight: 500;
          color: #3b1c5a;
          padding: 3px 10px 5px;
          border-radius: 12.5px;
          background-color: #3b1c5a0d;
        }
        .gift-point-wrap{
          position: relative;
          .gift-point-icon{
            width: 25px;
            height: 25px;
            margin-left: 6px;
            padding: 3px;
            border-radius: 50%;
            background-image: linear-gradient(133deg, #3b1c5a, #374db1 158%);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .gift-popup-wrap{
            padding: 10px 21px 10px 10px;
            border-radius: 3px;
            box-shadow: 0 1px 17px -2px #c5c5c5;
            background-color: #fff;
            justify-content: center;
            position: absolute;
            bottom: 100%;
            right: -20px;
            width: 220px;
            display: none;
            img{
              width: 25px !important;
              height: 25px !important;
            }
            span{
              font-size: 13px;
              font-weight: 600;
              color: #171a1d;
              margin-left: 5px;
            }
          }
          &:hover{
            .gift-popup-wrap{
              display: flex;
            }
          }
        }
      }
      .price-txt {
        position: absolute;
        top: 10px;
        border-radius: 3px;
        -webkit-backdrop-filter: blur(50px);
        backdrop-filter: blur(50px);
        background-color: rgba(23, 26, 29, 0.65);
        width: 200px;
        height: 30px;
        left: 10px;
        font-size: 12px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        strong {
          padding: 0px 10px;
          font-weight: normal;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }
}

// Renter Search module
.search-module-wrap{
  display: flex;
  .search-input-wrap{
    padding: 27px 30px;
    display: flex;
    border-radius: 3px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  .search-field{
    padding: 0 10px;
    min-width: 80px;
    .field{
      label{
        position: static;
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #5e6267;
      }
      input, .form-fields{
        padding: 0;
        border: 0;
        box-shadow: none;
        outline: none;
        font-size: 15px;
        font-weight: 500;
        color: #171a1d;
      }
      select{
        margin-left: -5px;
      }
    }
  }
  .search-button{
    .renter-btn{
      height: 100%;
      width: 100px;
      margin-left: -2px;
    }
  }
}

@import "../../theme/variables.scss";

.paragraph-text{
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 0.06px;
    line-height: 34px;
}

.list-text{
    letter-spacing: 0.05px;
    font-size: 19px;
}

.middle {
    &-landing {
        &-empty {
            min-height:650px !important;
            max-height:650px !important;
        }
        
        &-background {
            min-height:650px !important;
            background-image: url("/assets/img/hero_panel_secondary_header.jpg")  !important; 
            background-repeat: no-repeat !important;
            background-size: 100%  !important;
            background-position: center !important;
        }
    }
}

.top-landing-info {
    &.grey-text{
        color: $grey !important; 
    }
}

.client-container{
    width: 500px!important; 
    height: 310px!important; 
    background-color: #FFFFFF;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
    position: absolute;
    top: -50px;
    right: 100px;
    padding: 54px 48px;
    &:after{
        position: absolute;
        right: 24px;
        top: 0px;
        content: 'L';
        color: #9474b7;
        font-size: 80px;
        font-weight: 800;
    }
}

.client-image{
    position: absolute;
    bottom: -500px;
    width: 37%;
    height: 620px;
    left: 100px;
}

.detail-container-image{
    z-index: 2;
    position: absolute!important; 
    top: -85px;
    left: -62px;
    height: 350px!important; 
    width: 285px!important; 
}

.left-landing-text{
    padding: 0 10em 0 6em!important; 
}

.ui.grid.left-image-container{
    padding-right: 0 !important;
}

.property-text-container{
    background-color: $grey-background!important;
    margin: 4em!important;
    padding: 4em 5em!important;
    line-height: 1.4;
    div{
        font-size: 22px!important;
        font-weight: 300!important;
        letter-spacing: 0.06px!important;
    }
}

.aboutus-right-container{
    padding-right: 2em!important;
    .ui.container, header{
        background-color: $grey-main;
        border-radius: 4px;
        color: $white;
        margin-bottom: 16px;
        padding: 56px 48px 48px 18px;
    }
    
}

.aboutus-image-right img{
    display: initial!important;
}

.aboutus-text-right{
    .ui.header{
        color: $white;
    }
    p{
        font-style: italic;
    }
    span{
        color: #ABB4C0;
        font-size: 0.8em;
    }
}

.aboutus-left-container{
    line-height: 1.2;
    .ui.container{
        padding-top: 100px;
        padding-right: 1.7em;
    }
}

.warp-icon-and-heading {
    display: flex;
    align-items: center;
    padding: 0 !important;
}

.ml-25 {
    margin-left: 25px;
}

.left-landing-text .ui.grid+.grid {
    margin-top: 6rem;
}
@import '../../theme/variables.scss';

$top-padding: 240px;
$left-padding: 100px;
$slide-width: 390px;

.featured-properties {
  padding: 0 0 0 $left-padding;
  margin-bottom: 0;
  max-width: 100%;
  display: flex;
  position: relative;
  margin-top: 170px;
  .slick-slide {
    width: calc(390px + 30px) !important;
  }
  .ui.card {
    width: 390px !important;
    margin-right: 36px;
    cursor: pointer;
    border-radius: 4px;
    min-height: 410px;
    position: relative;
    min-width: 390px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid #e8e8e8;
    .extra {
      border: 0 !important;
    }
    .image {
      img {
        height: 264px;
        width: 100%;
      }
    }
  }

  &:before {
    z-index: -1;
    position: absolute;
    content: '';
    background-color: $grey-background;
    margin-top: -$top-padding;
    margin-left: -$left-padding;
    height: 650px;
    width: 100%;
  }

  &-card {
    width: $slide-width;
    margin-right: 36px;
  }

  .featured-properties-controls {
    display: flex;
    flex-wrap: wrap;
    min-width: 420px;
    .featured-properties-title {
      max-width: 100%;
      padding-right: 140px;
      color: #021e40;
      font-size: 40px;
      font-weight: 900;
      letter-spacing: 0.11px;
      line-height: 55px;
      margin-bottom: 0;
    }
    .featured-properties-link {
      color: #4d158a;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.06px;
      line-height: 27px;
      display: inline-block;
      margin: 40px 0 87px;
      svg:not(:root) {
        display: inline-block;
        vertical-align: -3px;
        margin-left: 7px;
      }
    }
    .featured-properties-arrows {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      width: 100%;
      height: 56px;
      background-color: #021e40;
      border-radius: 4px 4px 0 0;
      color: white;
      button {
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        
        &:nth-child(1) {
          margin-right: 32px;
          img {
            transform: rotate(180deg);
          }
        }

        &:focus {
          outline: 0;
        }
        &.featured-properties-arrows-disabled {
          opacity: 0.6;
          pointer-events: none;  
        }
      }

      &-disabled {
        opacity: 0.6;
      }
    }
  }

  :global(.slick-slider) {
    overflow: hidden;
  }

  :global(.slick-track) {
    width: 99999px !important;
  }
  :global(.slick-slide) {
    width: calc(#{$slide-width} + 30px) !important;
  }

  @media screen and (max-width: $tablet) {
    margin-bottom: 50px;
    padding: 30px 0 0 0;
    flex-direction: column;

    &:before {
      display: none;
    }

    &-card {
      width: 100%;
      max-width: calc(50vw - 5px);
      margin-right: 10px;
    }

    .controls {
      min-width: 100%;
      .title {
        padding-left: 20px;
        font-size: 32px;
        max-width: unset;
      }
      .link {
        padding-left: 20px;
        margin: 10px 0 10px;
      }
      .arrows {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $mobile) {
    &-card {
      width: 100%;
      max-width: 100vw;
    }
  }
}


.pointer {
  cursor: pointer;
}

.purple.spinner.huge.loading.icon {
  display: block;
  margin: auto;
}

#propertyCard-detail {
  font-size: 1em !important;
  i.inverted.bordered.purple.icon,
  i.inverted.circular.purple.icon {
    background-color: #4c158a!important;
  }
}


.ui.card .content.propertyCard-conatiner {
  font-size: 1em !important;
}


.loader-space {
  margin-top: 120px;
}
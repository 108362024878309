
.banner-service-provider{
    background: url('/assets/img/marketing/banner_service_provider.jpg') no-repeat center;
    background-size: cover;
    color: $white;
    padding-top: 95px;
    h1{
      font-size: 30px;
      font-weight: 800;
      margin: 85px 0 7px;
      font-family: "Be Vietnam", sans-serif !important;
      @media (max-width: 768px){
        font-size: 25px;
      }
    }
    h4{
      opacity: 0.7;
      font-size: 16px;
      margin-bottom: 146px;
      font-weight: normal;
      margin-top: 0px;
      font-family: "Be Vietnam", sans-serif !important;
      @media (max-width: 768px){
        font-size: 15px;
      }
    }
  }
  .half-image-section{
    padding: 120px 0 50px;
    @media (max-width: 768px){
      padding: 40px 0px;
      .row{
        flex-direction: column-reverse;
      }
    }
    &.benifit-section{
      padding: 100px 0;
      background-color: #fafafa;
      @media (max-width: 768px){
        padding: 50px 0;
        .row{
          flex-direction: unset;
        }
      }
      .text{
        padding-right: 29px;
      }
      h5{
        font-family: 'jenna_sueregular';
        margin-bottom: 0;
        font-size: 40px;
        line-height: 1.13;
        color: #171a1d;
        font-weight: normal;
      }
    }
    .badge{
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      color: $white;
      width: 160px;
      height: 30px;
      padding: 8px 25px;
      margin: 20px 0;
      border-radius: 15px;
      background-color: #11ae7d;
      text-transform: uppercase;
      @media (max-width: 768px){
        margin-top: 40px;
    margin-bottom: 0px;
      }
    }
    h3{
      font-size: 30px;
      font-weight: 800;
      line-height: 1.5;
      color: #3b1c5a;
      margin-bottom: 30px;
      font-family: 'Be Vietnam', sans-serif;
      @media (max-width: 768px){
        font-size: 25px;
        line-height: 1.8;
        margin-bottom: 10px;
        margin-top: 25px;
      }
    }
    .text{
      line-height: 2.12;
      color: #5e6267;
      margin-bottom: 20px;
      padding-right: 40px;
      @media (max-width: 768px){
        line-height: 2.31;
        font-size: 13px;
        padding-right: 0;
      }
    }
    .image-container{
      text-align: right;
      position: relative;
      img{
        max-width: 100%;
      }
      .fixed-left-image{
        position: absolute;
        left: 0;
        bottom: 30px;
        width: 300px;
        height:60.31px;

        @media (max-width: 768px){
          left: -43px;
        }
      }
      .fixed-square-image{
        position: absolute;
        right: -30px;
        bottom: -30px;
        z-index: -1;
        @media (max-width: 768px){
          right: -7px
        }
      }
      .main-image{
        @media (max-width: 768px){
          width: 100%;
          padding-right: 20px;
        }
      }
    }
  }
  .full-back-image-section{
    padding: 60px 0 100px;
    @media (max-width: 768px){
      padding: 50px 0 50px;
    }
    .image-container{
      background: url("/assets/img/marketing/sp_image_2.jpg") no-repeat center;
      background-size: cover;
      color: $white;
      padding: 160px 100px;
      width: 100%;
      border-radius: 3px;
      @media (max-width: 768px){
        padding: 70px 30px;
      }
      h5{
        font-family: 'jenna_sueregular';
        font-size: 40px;
        line-height: 1.13;
        margin-bottom: 0;
        @media (max-width: 768px){
          font-size: 30px;
        }
      }
      h3{
        font-size: 30px;
        font-weight: 800;
        line-height: 1.5;
        max-width: 670px;
        margin-top: 0px;
        @media (max-width: 768px){
          font-size: 20px;
        }
      }
    }
  }
  .bottom-form-section{
    background: url('/assets/img/marketing/bg-email-suscription.png') no-repeat center;
    background-size: cover;
    color: $white;
    padding: 100px 0;
    text-align: center;
    margin-top: 320px;
    position: relative;
    @media (max-width: 768px){
      padding: 50px 0px;
    }
    .text-container{
      max-width: 800px;
      margin: 0 auto;
      h3{
        font-size: 30px;
        font-weight: 800;
        line-height: 1.5;
        color: #ffffff;
        margin-bottom: 10px;
        font-family: "Be Vietnam", sans-serif;
        @media (max-width: 768px){
          font-size: 20px;
        }
      }
      h4{
        opacity: 0.7;
        font-size: 17px;
        line-height: 2;
        margin-bottom: 35px;
        padding: 0 20px;
        margin-top: 0px;
        font-family: "Be Vietnam", sans-serif;
        font-weight: normal;
        @media (max-width: 768px){
          font-size: 13px;
          line-height: 1.92;
        }
      }
      .input-with-button{
        max-width: 500px;
        margin: 0 auto;
        display: flex;
        width: 100%;
        @media (max-width: 540px){
          display: block;
        }
        .form-control{
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
          width: 350px;
          height: 50px;
          padding: 15px 25px 16px 25px;
          border-radius: 0;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          box-shadow: none;
          @media (max-width: 540px){
           width: 100%;
           border-radius: 3px;
           margin-bottom: 20px;
          }
        }
        .btn-dark{
          width: 150px;
          height: 50px;
          padding: 0 25px;
          border-radius: 0;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          background-color: #171a1d;
          @media (max-width: 540px){
            width: 100%;
           }
        }
      }
    }
  }
  
.signin-bg{
    background: url("/assets/img/Auth/bg-signin-modal.png") #fff no-repeat left top;
}
.modals{
  z-index: 9999 !important;
}
  .modal-header{
    border-bottom: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 40px 0 50px;
    .modal-title{
      font-size: 22px;
      font-weight: 800;
      line-height: normal;
      color: #171a1d;
      margin-bottom: 5px;
      width: 100%;
      text-align: center;
      font-family: "Be Vietnam", sans-serif !important;
      @media (max-width: 768px){
        font-size: 18px;
      }
    }
    .modal-sutitle{
      font-size: 16px;
      line-height: normal;
      color: #5e6267;
      @media (max-width: 768px){
        font-size: 14px;
      }
    }
    .close{
      position: absolute;
      right: 8px;
      top: 7px;
      line-height: 0;
      margin: 0;
      padding: 20px;
    }
  }
  .modal-body{
    padding: 0 50px 50px;
    @media (max-width: 768px){
      padding: 0 20px 20px;
    }
    .modal-large-btn{
      display: flex;
      width: 100%;
      border-radius: 3px;
      border: solid 1px transparent;
      background-color: rgba(#171a1d, 0.03);
      padding: 25px 30px 27px;
      text-align: left;
      text-transform: none;
      align-items: center;
      margin-bottom: 20px;
      & + .modal-large-btn{
        margin-left: 0;
        margin-bottom: 35px;
      }
      &:hover{
        border: solid 1px rgba(59, 28, 90, 0.15);
        background-color: #f0eef2;
        .text-heading,
        .text-des{
          color: #3b1c5a;
        }
        svg{
          path + path{
            fill: #3b1c5a;
          }
        }
      }
    }
    .image-section{
      margin-right: 15px;
    }
    .text-heading{
      font-size: 14px;
      font-weight: 500;
      color: #171a1d;
    }
    .text-des{
      font-size: 12px;
      font-weight: 500;
      color: #5e6267;
    }
  }
  .forgot-modal{
    background-color: #fafafa;
    .modal-header{
      padding: 50px 50px 30px;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 768px){
        padding: 20px;
      }
      .modal-title{
        text-align: left;
      }
      .modal-sutitle{
        margin-bottom: 0;
      }
    }
    .form-group{
      margin-bottom: 40px;
    }
    .modal-body{
      padding: 0 50px 55px;
      @media (max-width: 768px){
        padding: 0px 20px 20px
      }
    }
  }
  .success-modal{
    background-color: #fafafa;
    .modal-header{
      padding: 50px 50px 55px;
    }
    .success-image{
      margin-bottom: 30px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-shadow: 0 5px 6px -4px #e5eced;
      background-color: #ffffff;
    }
    .modal-sutitle{
      font-size: 15px;
      line-height: 1.6;
      text-align: center;
      color: #5e6267;
      margin-bottom: 40px;
    }
  }
  
  // Welcome to Leasra Modal 
  .welcome-leasraModal {
    .modal-header {
      background: url("/assets/img/Auth/welcome-modalBanner.png") no-repeat;
      background-size: contain;
      padding-bottom: 30px;
      p {
        font-size: 16px;
        color: #5e6267;
      }
    }
    .modal-body {
      padding-bottom: 0;
    }
    .modal-content {
      background-color: #fafafa;
    }
    .welcome-leasraItem {
      border-radius: 3px;
      border: solid 1px rgba(59, 28, 90, 0.15);
      background-color: #f0eef2;
      display: flex;
      padding: 25px 30px;
      margin: 0 0 20px;
      &:nth-child(2) {
        background: #f3f3f3;
        border-color: transparent;
      }
    }
    .welcome-leasraIcon {
      margin-right: 15px;
      flex: none;
    }
    .welcome-leasraContent {
      flex: 1;
      h5 {
        font-size: 14px;
        font-weight: 500;
        color: #3b1c5a;
        margin: 0;
      }
      p {
        font-size: 12px;
        font-weight: 500;
        color: #5e6267;
        margin: 0;
      }
    }
    .modal-footer {
      padding: 15px 50px 50px;
      border: 0;
      .btn {
        padding: 15px 28px;
        border-radius: 3px;
        min-width: 100%;
        font-size: 13px;
        font-weight: 800;
        margin: 0;
        background-image: linear-gradient(96deg, #3b1c5a, #374db1 163%);
      }
    }
  }
  
.homepage {
  overflow-x: hidden;
  .container {
    @media (max-width: 992px) {
      max-width: 100% !important;
      padding: 0px 20px !important;
    }
  }
  .home-image-section {
    .heading4 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.75;
      color: #171a1d;
      margin: 0 0 20px;
      font-family: "Be Vietnam", sans-serif;
      @media (max-width: 768px) {
        font-size: 15px;
        line-height: 1.93;
        margin: 0 0 20px;
      }
    }
    .fixed-square-image {
      z-index: -1;
    }
  }
  .home-banner {
    // background-image: url(/assets/img/marketing/banner_homepage.jpg);
    height: 660px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 768px) {
      height: 550px;
    }
    .carousel-bg-img{
      max-height: 660px;
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
    }
    .container-fluid
    .carousel-inner,
    .container,
    .carousel-item,
    .carousel {
      height: 100%;
    }
    .carousel-item{
      height: 660px !important;
      @media (max-width: 768px) {
       display:inherit
      }
    }
    .carousel-caption {
      text-align: left;
      // margin: 0 auto;
      // bottom: auto;
      // top: 50%;
      // left: 0;
      // transform: translate(10px, -50%);
      // position: static;
      .carousel-caption-wrap{
        width: 555px;
        // margin-left: -20px;
        @media (max-width: 768px) {
        // overflow-wrap: break-word;
        width:100%;
        // word-break: keep-all;
        // word-wrap: break-word;
        // hyphens: auto;
        }
      }
      h5 {
        font-size: 30px;
        font-weight: 800;
        font-family: "Be Vietnam", sans-serif;
        @media (max-width: 768px) {
          font-size: 25px;
          line-height: 1.4;
          margin-bottom: 10px;
        }
      }
      p {
        opacity: 0.8;
        font-size: 16px;
        line-height: 2;
        margin: 20px 0 40px;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 15px;
          margin: 10px 0;
      
        }
      }
      .carousel-btns{
        display: flex;
        .demobtn{
          background-color: #fff;
          color: #171a1d;
          margin-left: 20px;
          font-size: 13px;
          font-weight: 800;
          border-radius: 3px;
          padding: 12px 20px 14px;
          text-transform: uppercase;
          display: inline-block;
          text-align: center;
          cursor: pointer;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
    &.renter-banner {
      background-image: url(/assets/img/marketing/renter-banner.jpg);
    }

  }

    // Banner points
    .points-card-wrap{
      @media (max-width: 1048px){
        display:none;
      }
      position: absolute;
      right: 6%;
      bottom: 0;
      width: 260px;
      height: 208px;
      border-radius: 3px;
      background-image: linear-gradient(127deg, #3b1c5a, #374db1 159%);
      padding: 20px;
      text-align: center;
      .points-title{
        font-size: 16px;
        font-weight: 800;
        color: #fff;
        margin-top: 10px;
        text-transform: uppercase;
        font-family: "Be Vietnam", sans-serif;
      }
      .points-subtitle{
        font-size: 12px;
        line-height: 1.67;
        color: #fff;
        opacity: 0.8;
        margin-top: 5px;
        font-family: "Be Vietnam", sans-serif;
      }
    }
  .homebtn {
    background-image: linear-gradient(98deg, #3b1c5a, #374db1 163%);
    color: #fff;
    min-width: 280px;
    font-size: 13px;
    font-weight: 800;
    border-radius: 3px;
    padding: 12px 10px 14px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    @media (max-width: 414px){
      min-width: 100%;
    }
    &:hover {
      text-decoration: none;
    }
  }
  .home-widgets {
    background-color: #fafafa;
    padding: 0 0 60px;
    @media (max-width: 768px) {
      padding: 0 0 35px;
    }
    .widget-col {
      display: flex;
      margin: 60px 0 0;
      @media (max-width: 768px) {
        margin: 35px 0 0;
      }
    }
    .widget-image {
      flex: none;
      width: 50px;
      height: 50px;
      padding: 12px;
      margin: 0 15px 0 0;
      text-align: center;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
      @media (max-width: 768px) {
        width: 35px;
        height: 35px;
        padding: 2px;
      }
    }
    .widget-content {
      h4 {
        font-size: 18px;
        font-weight: 800;
        color: #3b1c5a;
        margin-bottom: 8px;
        @media (max-width: 768px) {
          font-size: 15px;
          margin-bottom: 0px;
        }
      }
      p {
        font-size: 13px;
        font-weight: 500;
        color: #5e6267;
        @media (max-width: 768px) {
          font-size: 11px;
        }
      }
    }
  }
  .home-marketplace {
    margin: 100px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 100px;
    @media (max-width: 768px) {
      margin: 0;
      padding-right: 0;
    }
    .home-marketplace-watermark {
      position: absolute;
      right: 70px;
      bottom: -25px;
      z-index: 9;
    }
    .home-marketplace-banner {
      position: relative;
      z-index: 20;
      height: 600px;
      object-fit: cover;
      @media (max-width: 768px) {
        height: 490px;
      }
    }
    .home-markeplaceContent {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      z-index: 60;
      transform: translate(10px, -50%);
      @media (max-width: 768px) {
        top: 60%;
      }
    }
    .marketPlatform-btn {
      background: #ffffff;
      color: #171a1d;
      min-width: 300px;
      font-size: 13px;
      font-weight: 800;
      border-radius: 3px;
      padding: 12px 0 14px;
      text-transform: uppercase;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
    .carousel-inner {
      min-height: 220px;
      @media (max-width: 768px) {
        min-height: auto;
      }
    }
    .carousel-indicators {
      position: static;
      transform: none;
      margin: 0 0 40px;
    }
    .carousel-caption {
      font-size: 30px;
      font-weight: bold;
      text-align: left;
      position: static;
      @media (max-width: 768px) {
        font-size: 20px;
        font-weight: normal;
      }
      p {
        margin: 8px 0;
        &:nth-child(1){
          @media (max-width: 768px) {
            font-weight: bold;
          }
        }
        
      }
    }
  }
  .home-platform {
    background-image: linear-gradient(105deg, #3b1c5a, #374db1 162%);
    padding: 134px 0 50px;
    margin-top: -134px;
    @media (max-width: 768px) {
      padding: 25px 0 50px;
      margin-top: 0;
    }
    .home-platform-col {
      display: flex;
      color: #fff;
      margin: 64px 0 0;
      @media (max-width: 768px) {
        margin: 25px 0 0;
      }
      .platform-image {
        flex: none;
        width: 40px;
        height: 40px;
        padding: 4px 8px;
        margin: 0 20px 0 0;
        border-radius: 3px;
        background-color: rgba(255, 255, 255, 0.2);
      }
      h4 {
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 5px;
        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
      p {
        opacity: 0.7;
        font-size: 13px;
        line-height: 1.69;
        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 1.83;
        }
      }
    }
  }
  .renter-section {
    background-color: #fafafa;
    padding-bottom: 100px;
    .row {
      @media (max-width: 768px) {
        flex-direction: unset;
      }
    }
    .image-container {
      text-align: left;
      padding-right: 120px;
      z-index: 99;
      @media (max-width: 768px) {
        margin-top: 25px;
        padding-right: 0px;
      }
      .main-image {
        position: relative;
        z-index: 9;
        @media (max-width: 768px) {
          width: 100%;
          padding-right: 0px;
          padding-left: 15px;
        }
      }
      .fixed-top-image {
        position: absolute;
        top: 35px;
        right: 70px;
        width: 200px;
        z-index: 9;
        height: 200px;
        object-fit: contain;
        @media (max-width: 768px) {
          width: 150px;
          height: 150px;
          right: -27px;
        }
        @media (max-width: 414px) {
          width: 120px;
          height: 120px;
          bottom: -26px;
        }
      }
      .fixed-left-image {
        position: absolute;
        left: -65px;
        bottom: 15px;
        z-index: 99;
        width: 300px;
        @media (max-width: 768px) {
          left: -42px;
        }
        @media (max-width: 414px) {
         bottom: -27px;
        }
      }
    }
    @media (max-width: 768px) {
      padding-bottom: 40px;
      .mt-5 {
        margin-top: 20px !important;
      }
    }
    .fixed-square-image {
      position: absolute;
      left: -27px;
      top: -27px;
      bottom: auto;
      right: auto;
      @media (max-width: 768px) {
        // left: 18px;
      }
    }
    .mobile-text{
      margin-bottom: 0px;
    }
  }
  .propertyOwner-section {
    padding-bottom: 100px;
    @media (max-width: 768px) {
      padding-bottom: 50px;
    }
    .image-container {
      padding-left: 100px;
      @media (max-width: 768px) {
        padding-left: 0px;
      }
    }
    .main-image {
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .fixed-top-image {
      width: 200px;
      height: 200px;
      object-fit: contain;
      position: absolute;
      right: -50px;
      top: 45px;
      @media (max-width: 768px) {
        width: 150px;
        height: 150px;
        right: -27px;
        top: 30px;
      }
      @media (max-width: 414px) {
        width: 120px;
        height: 120px;
      }
    }
    .fixed-left-image {
      position: absolute;
      left: 40px;
      bottom: 20px;
      z-index: 9;
      width: 300px;
      @media (max-width: 768px) {
        left: -40px;
      }
      @media (max-width: 414px) {
        bottom: -26px;
      }
    }
    .fixed-square-image {
      bottom: auto;
      top: -25px;
      right: -25px;
      z-index: -1;
    }
    .mobile-text{
      display: block;
      @media (max-width: 768px){
        display: none;
      }
    }
  }
  .whyLeasera-section {
    text-align: center;
    padding: 100px 0;
    position: relative;
    background-color: #fafafa;
    @media (max-width: 768px) {
      padding: 50px 0px;
    }
    .whyLeasera-video {
      margin: 50px 0 0;
      margin-left: auto;
      margin-right: auto;
      width: 60%;
      height:375px;
      @media (max-width: 768px) {
        width: 100%;
        height:203px;
      }
    }
    .whyLeasera-content {
      margin: 80px 0 0;
      position: relative;
      // display: flex;
      .whyLeasera-inner {
        position: relative;
        padding: 40px 40px 0;
        border-radius: 3px;
        display: flex;
        z-index: 11;
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.02);
        background-color: #fff;
        @media (max-width: 768px) {
          padding: 0px;
          flex-wrap: wrap;
        }
      }
      .fixed-square-image {
        left: -25px;
        bottom: -25px;
        position: absolute;
        z-index: 1;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    .whyLeasera-text {
      flex: 1;
      text-align: left;
      font-size: 19px;
      line-height: 2.26;
      color: #5e6267;
      position: relative;
      padding: 10px;
      @media (max-width: 768px) {
        flex: unset;
        padding: 20px;
        font-size: 14px;
        line-height: 2.5;
      }
      span {
        position: absolute;
        left: -40px;
        top: 10px;
        font-size: 400px;
        line-height: 50%;
        opacity: 0.06;
        @media (max-width: 768px) {
          left: -20px;
          font-size: 310px;
        }
      }
    }
    .whyLeasera-image {
      position: relative;
      margin-bottom: -40px;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 0px;
        img {
          margin: 0px auto;
        }
      }
    }
    h4 {
      font-size: 30px;
      font-weight: 800;
      color: #171a1d;
      margin: 25px 0 15px;
      font-family: "Be Vietnam", sans-serif;
      @media (max-width: 768px) {
        font-size: 25px;
        margin-bottom: 0px;
      }
    }
    p {
      font-size: 16px;
      line-height: 2;
      margin: 0;
      color: #5e6267;
      max-width: 700px;
      margin: 0px auto;
      width: 100%;
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
    .badge-btn {
      @include badgeBtn;
    }
    .mobile-text{
      display: none;
      @media (max-width: 768px){
        display: block;
      }
    }
    .desktop-text{
      @media (max-width: 768px){
        display: none;
      }
    }
  }
  .happyRenter-section {
    padding: 100px 0;
    @media (max-width: 768px) {
      padding: 60px 0px;
    }
    .happyRenter-col {
      margin: 20px 0 0;
      .badge-btn {
        @include badgeBtn;
      }
      .happyRenter-icon {
        position: absolute;
        left: -10px;
        top: 5px;
        line-height: 50%;
        font-size: 400px;
        opacity: 0.05;
      }
      h4 {
        font-size: 20px;
        font-weight: 800;
        line-height: 1.5;
        color: #171a1d;
        width: 200px;
        margin: 20px 0 10px;
        font-family: "Be Vietnam", sans-serif;
      }
      p {
        font-size: 13px;
        line-height: 2.08;
        color: #5e6267;
      }
    }
    .happyRenter-carousel {
      position: relative;
      @media (max-width: 768px) {
        margin-top: 20px;
      }
      .fixed-square-image {
        position: absolute;
        left: 7px;
        top: -7px;
        @media (max-width: 768px) {
          left: 8px;
          top: -5px;
        }
      }
      .card-carousel {
        display: flex;
      }
      .card {
        padding: 15px 20px;
        border-radius: 3px;
        margin: 0;
        border: 0;
        @media (max-width: 768px) {
          padding: 10px 15px;
        }
        .card-wrap {
          background-color: #fff;
          box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
          padding: 30px;
        }
        p {
          margin: 0;
        }
      }
      .card-header {
        display: flex;
        background: transparent;
        border: 0;
        padding: 0;
      }
      .card-image {
        flex: none;
        width: 50px;
        height: 50px;
        margin: 0 15px 0 0;
        border-radius: 3px;
        overflow: hidden;
        background: #fafafa;
        img {
          max-width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .card-title {
        flex: 1;
      }
      .card-name {
        font-size: 14px;
        font-weight: 500;
        color: #171a1d;
      }
      .card-locate {
        font-size: 13px;
        line-height: 1.69;
        color: #5e6267;
      }
      .card-content {
        margin: 25px 0 0;
        h5 {
          font-size: 16px;
          font-weight: 800;
          color: #171a1d;
          font-family: "Be Vietnam", sans-serif;
        }
        p {
          font-size: 13px;
          line-height: 2;
          color: #5e6267;
        }
      }
      .carousel-indicators {
        background: #747577;
        position: static;
        margin: 0 20px 0 auto;
        transform: none;
      }
    }
  }
}

.carousel-indicators,
.slick-dots {
  padding: 5px 0;
  opacity: 0.9;
  border-radius: 8px;
  width: 70px;
  // left: 50%;
  right: auto;
  transform: translate(30%, -20px);
  margin: 0;
  background-color: #171a1d;
  bottom:0;
  list-style: none;
  display: flex !important;
  ul {
    list-style: none;
    display: flex;
  }
  li {
    width: 6px;
    height: 6px;
    border: 0;
    border-radius: 100%;
  }
}
.slick-dots {
  background-color: rgba(23, 26, 29, 0.6);
  // transform: unset;
  right:45%;
  left: auto;
  justify-content: center;
  li {
    margin: 0px 2px;
    button {
      width: 6px;
      height: 6px;
      padding: 0px;
      &:before {
        line-height: 10px;
        width: 6px;
        height: 6px;
        opacity: 0.3 !important;
        color: #ffffff !important;
      }
    }
    &.slick-active {
      button {
        &:before {
          opacity: 1 !important;
          color: #ffffff !important;
        }
      }
    }
  }
}

// mobile text
.mobile-text{
  display: none;
  @media (max-width: 768px){
    display: block;
  }
}
.desktop-text{
  @media (max-width: 768px){
    display: none;
  }
}

.home-carousel{
  min-height: 300px;
  padding: 40px 0;
  background-color: #fafafa;
  border-bottom: 2px solid #f5f5f5;
  .carousel-listing-wrap{
    display: flex;
    margin: 0 -12px;
    .listing-card{
      width: 280px;
      margin: 0 12px;
      box-shadow: 0 11px 14px -10px #e5eced;
      .listing-card-top{
        height: 150px;
        .listing-card-img{
          height: 100%;
          width: 100%;
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
          }
        }
      }
      .listing-card-bottom{
        padding: 25px 15px;
        background-color: #fff;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        .listing-title{
          font-weight: 600;
          color: #171a1d;
          word-break: break-word;
        }
        .listing-subtitle{
          font-size: 13px;
          letter-spacing: normal;
          color: #5e6267;
          margin-top: 5px;
          word-break: break-word;
        }
        .listing-info-row{
          display: flex;
          align-items: center;
          margin: 8px -5px 0;
          .listing-info-col{
            font-size: 13px;
            font-weight: 500;
            color: #3b1c5a;
            margin: 0 5px 8px;
          }
          .dot-divider{
            width: 4px;
            height: 4px;
            border-radius: 50%;
            margin: 0 5px 5px;
            background-color: #6b66ff;
            opacity: .3;
          }
        }
      }
    }
  }
}

.section-title{
  font-size: 30px;
  font-weight: 800;
  line-height: 1.5;
  text-align: center;
  color: #171a1d;
  text-transform: uppercase;
  font-family: "Be Vietnam", sans-serif;
}
.section-subtitle{
  font-size: 16px;
  line-height: 2;
  text-align: center;
  color: #5e6267;
  margin-top: 8px;
  max-width: 700px;
  font-family: "Be Vietnam", sans-serif;
  margin-left: auto;
  margin-right: auto;
}

// Leasera partners
.leasera-partners{
  background-color: #fff;
  padding: 50px 10px;
  .partners-list-wrap{
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    .partners-list{
      width: 25%;
      padding: 20px 35px;
      text-align: center;
      @media (max-width: 768px){
        width: 50%;
      }
      img{
        max-width: 100%;
        height: auto;
        width:auto;
        max-height: 7400px;
        filter: grayscale(100%);
        &:hover{
          filter: grayscale(0);
        }
      }
    }
  }
}
// Download apps
.download-apps{
  padding: 0 70px;
  position: absolute;
  width: 100%;
  top: -200px;
  @media (max-width: 768px){
    // position:inline-block;
    padding:0px;
   top:-300px !important;
  }
  .downlaod-card-wrap{
    padding: 0 30px 0 50px;
    border-radius: 3px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
    background-color: #fafafa;
    display: flex;
    @media (max-width: 768px){
      display:inline-block;
      // padding-bottom:500px !important;
    }
    .card-left{
      padding: 40px 0;
      max-width: 450px;
      text-align: left;
      margin-right: 30px;
      .card-title{
        font-size: 20px;
        font-weight: 800;
        color: #171a1d;
      }
      .card-subtitle{
        font-size: 15px;
        line-height: 2;
        color: #171a1d;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    .card-right{
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      @media (max-width: 768px){
        display:none;
        // padding-bottom:500px !important;
      }
    }
  }
}

@import "../../theme/variables.scss";

.search-container{
    background-color: white;
    width: 100%;
    padding: 3rem 4rem;
    width: 900px!important;
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    text-align: left;
    box-shadow:  0 0 30px 0 rgba(0, 0, 0, 0.12)!important;
    border-radius: 5px;
    font-size: 1.5rem;
}

.date-container{
    display: flex;
}

.ui.form input .property-search{
    background-color: white !important;
}

.inner-dropdown{
    overflow: inherit!important;
}

.menu-align-right{
    text-align: right !important;
}

// .react-datepicker-wrapper{
//     // width: 30%;
//     .react-datepicker__input-container input{
//         border: none !important;
//         background-color: #F2F3F5 !important;
//         line-height: 30px;
//         color: #96A0B4!important;
//         transition: 0.4s;
//         border-radius: 0px!important;
//     }
// }


.ui {
    &.form {
        .field {
            &.property-search-duration-field {
                padding-left: 1em !important;
                .ui {
                    &.selection {
                        &.active {
                            &.drowdown {
                                &:hover {
                                    box-shadow: none;
                                }
                            }
                        }
                        &.dropdown {
                            background-color: transparent !important;
                            color: #4d158a;
                            padding-left: 0;
                            padding-top: 5px;
                            box-shadow: none;
                            &:focus {
                                border: 0 !important;
                            }
                            &:not(.button)>.default.text {
                                color: #4d158a;
                            }
                            .dropdown {
                                &.icon {
                                    display: none;
                                }
                            } 
                        }
                    }
                }
            }
            &.field {
                &.property-search-duration-field {
                    input, input:focus {
                        background-color: transparent !important;
                        color:#4d158a ;
                        padding-left: 0;
                        padding-top: 5px;
                        outline: 0;
                        border: 0 !important;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


.ui.form {
	.field.field.location-search-field {
		input {
            padding-left: 43px;
            &:focus {
                border: 0 !important;
            }
		}
        .search {
            &.icon {
                position: absolute;
                top: 20px;
                left: 12px;
            }
        }
    }
    .ui.primary.button.main-button {
        height: 50px;
        align-self: flex-end;
        margin-bottom: 2px;
        line-height: 15px;
    }
}




.ui.form .fields.min-max-input {
    margin-top: 13px;
    margin-left: 1px;
    margin-right: 1px;
}

.ui.pointing.dropdown>.menu.beds-inner-dropdown {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: 0;
    &::after {
        display: none;
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

.search-container .ui.form .field.field input,
.search-container .ui.fluid.dropdown .ui.selection.dropdown {
    border: 1px solid transparent !important;
}
.search-container .ui.form .field.field input:focus,
.search-container .ui.fluid.dropdown .ui.selection.dropdown:focus {
    border: 1px solid #c9f2ee !important;
    .default.text {
        color:#4d158a;
    }
}


.search-container .ui.form .field.field.location-search-field input {
    padding-left: 20px;
}